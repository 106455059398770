<template>
 <div class="overflow-hidden bg-white py-24 sm:pt-28 sm:pb-32 h-full">
   
   <div class="mx-auto lg:max-w-7xl md:max-w-5xl sm:max-w-2xl">
   <div
    class="mx-auto sm:grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
    <div class="lg:pr-8 lg:pt-4">
     <div class="lg:max-w-lg">
      <h2 class="text-base font-semibold leading-7 encom_primary_text">
       {{ $t("Expert Statistics") }}
      </h2>
      <p
       class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
       <!-- {{ $t("secondPrimaryTextNoAuthPage") }} -->
       Améliorez la satisfaction client et la productivité des équipes
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">
       <!-- {{ $t("paragraphNoAuthPage") }}. -->
       Analysez vos données par Numéros, par File d’appels ou par Utilisateurs,
       pour identifiez les appels perdus, ajuster vos ressources et réduire les
       temps d’attente.
      </p>
      <div
       class="mt-10 flex justify-end gap-8 max-w-lg text-white leading-7 text-gray-600 lg:max-w-none">
       <!-- <a
        href="https://clients-labs.cx-engine.net"
        target="_blank"
        class="bg-black px-4 py-2 shadow-md rounded-full text-white hover:shadow-lg hover:bg-gray-900 font-bold text-sm">
        Jouer avec la Démo
       </a> -->

       <button
        @click="this.$refs.sales.show()"
        class="bg-red-600 px-4 py-2 shadow-md rounded-full text-white hover:shadow-lg hover:bg-red-500 font-bold text-sm">
        Demandez l’activation
       </button>
      </div>
     </div>
    </div>
    <!-- <img
     src="../assets/demo-expert-stats.png"
     alt="Product screenshot"
     class="max-w-7xl rounded-xl shadow-xl ring-1 ring-gray-200 md:-ml-4 lg:-ml-0" /> -->
    <iframe
     src="https://www.youtube.com/embed/M3xCymv94JE"
     class="w-full h-full rounded-xl"
     frameborder="0"
     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
     allowfullscreen>
    </iframe>
   </div>
  </div>
 </div>
 <SalesContactCard
  ref="sales"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)" />
</template>

<script>
import axios from "axios";

import SalesContactCard from "./SalesContactCard.vue";

export default {
 props: [""],
 components: { SalesContactCard },
 data() {
  return {};
 },
 methods: {},
 mounted() {
  this.$emit("setLoading", false);
 },
};
</script>

<style></style>
