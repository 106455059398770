<template>
 <popup-modal ref="popup">
  <TransitionRoot as="template" :show="open">
   <Dialog as="div" class="fixed z-40 inset-0 overflow-y-auto" @close="_cancel">
    <div
     class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="ease-in duration-200"
      leave-from="opacity-100"
      leave-to="opacity-0">
      <DialogOverlay
       class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
     </TransitionChild>

     <!-- This element is to trick the browser into centering the modal contents. -->
     <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
      >&#8203;</span
     >
     <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leave-from="opacity-100 translate-y-0 sm:scale-100"
      leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
      <div
       class="sm:my-8 sm:align-middle sm:max-w-3xl sm:h-auto sm:w-full sm:p-6 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all">
       <div class="items-center gap-2">
        <form @submit.prevent="sendForm()" method="POST">
         <div class="space-y-12">
          <div class="border-b border-gray-900/10 pb-12">
           <h2 class="text-xl font-semibold leading-7 text-gray-900">
            <!-- {{ $t("yourFeedback") }} -->
            Contactez notre équipe commerciale
           </h2>
           <p class="text-sm leading-6 text-gray-600">
            <!-- {{ $t("sendYourFeedbackDescription") }} -->
            Notre équipe vous contactera bientôt au sujet de l'activation de vos
            experts statistiques .
           </p>

           <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <!-- <div class="sm:col-span-6 mx-4">
             <label
              for="subject"
              class="block text-sm font-medium leading-6 text-gray-900">
              {{ $t("subject") }}
             </label>
             <div class="mt-1">
              <input
               required
               v-model="subject"
               disabled
               type="text"
               name="subject"
               id="subject"
               minlength="2"
               maxlength="255"
               class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
             </div>
            </div> -->

            <div class="sm:col-span-6 mx-4">
             <label
              for="content"
              class="block text-sm font-medium leading-6 text-gray-900">
              {{ $t("yourMessage") }}
             </label>
             <div class="mt-1">
              <textarea
               required
               v-model="content"
               rows="5"
               name="content"
               id="content"
               minlength="2"
               maxlength="65000"
               class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" />
             </div>
            </div>
           </div>
          </div>
         </div>
         <div class="mt-6 flex items-center justify-end gap-x-2"></div>
        </form>
       </div>
       <!-- <div class="text-center w-full h-auto pt-4">
           <iframe
            src="https://www.youtube.com/embed/17tuys6i0RM"
            class="w-full video-height rounded-xl"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
           </iframe>
          </div> -->
       <div class="sm:mt-4 sm:flex justify-end gap-4">
        <button
         type="button"
         @click="_cancel()"
         class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none">
         {{ $t("cancel") }}
        </button>
        <button
         type="button"
         @click="_confirm()"
         class="rounded-md encom_primary encom_primary_hover px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
         {{ $t("ticket.send") }}
        </button>
       </div>
      </div>
     </TransitionChild>
    </div>
   </Dialog>
  </TransitionRoot>
 </popup-modal>
</template>

<script>
import PopupModal from "./PopupModal.vue";
import axios from "axios";
const account = localStorage.getItem("account");
import { ref } from "vue";
import {
 Dialog,
 DialogOverlay,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

const timezone = localStorage.getItem("hostTimeZone");

export default {
 props: [
  "elementOption",
  "parseDateRange",
  "parseTimeRange",
  "periodSelector",
  "selectHostName",
  "selectHostContext",
  "selections",
  "timeOption",
  "time",
  "startDate",
  "endDate",
 ],
 setup() {
  const open = ref(true);

  return {
   open,
  };
 },
 data() {
  return {
   account,
   content: "",
   subject: "Activation des expert statistiques",
  };
 },
 components: {
  ExclamationCircleIcon,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  CheckIcon,
  PopupModal,
  Switch,
  SwitchGroup,
  SwitchLabel,
 },
 methods: {
  show(opts = {}) {
   // Once we set our config, we tell the popup modal to open
   this.$refs.popup.open();
   // Return promise so the caller can get results
   return new Promise((resolve, reject) => {
    this.resolvePromise = resolve;
    this.rejectPromise = reject;
   });
  },
  _confirm() {
   try {
    this.sendForm();
    this.$refs.popup.close();
    this.resolvePromise(true);
   } catch (error) {
    console.error(error);
   }
  },
  _cancel(notShowAgain) {
   if (notShowAgain) {
    localStorage.setItem("dashboard_tutorial", 0);
   }
   this.$refs.popup.close();
   this.resolvePromise(false);
   // Or you can throw an error
   // this.rejectPromise(new Error('User cancelled the dialogue'))
  },
  async sendForm() {
   const options = {
    method: "GET",
    url: `${this.$cookie.getCookie(
     "API"
    )}/api/v1/customer-services/activation-request?customerAccount=${
     this.account
    }&service=expert-stats&message=${this.content}`,
    data: {
     message: this.content,
     service: "expert-stats",
    },
    headers: {
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   axios
    .request(options)
    .then(res => {
     const msg = "Merci. Notre équipe vous contactera bientôt";
     this.$emit("closeForm");
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
    })
    .catch(err => {
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
 },
 mounted() {
  // this.subject = this.subject + " - " + this.account;
 },
 computed: {
  ...mapGetters([
   "pbxElements",
   "pbxTimeSelected",
   "pbxTimeSelectedEnd",
   "hostName",
   "user",
  ]),
 },
 watch: {},
};
</script>

<style scoped>
.video-height {
 height: 500px;
}
</style>
